import React, { Fragment } from 'react';
import { FetchMoreQueryOptions } from '@apollo/client';
import { StyledList } from '@fuww/library/src/Article';
import LoadMore from '../../LoadMore';
import loadMore from '../../../lib/queries/loadMore';
import {
  ArticlesConnectionQuery,
  ArticlesConnectionQueryVariables,
  AuthorByEmailQuery,
  AuthorByEmailQueryVariables,
  NewsArticle,
} from '../../../lib/graphql/api/graphql';
import NewsFeedListItem from './NewsFeedListItem';

const ArticleList = <
  Q extends ArticlesConnectionQuery | AuthorByEmailQuery,
  V extends ArticlesConnectionQueryVariables | AuthorByEmailQueryVariables,
>({
    variables,
    fetchMore,
    field,
    connectionField,
    data,
    loading,
    showLoader,
    shouldLoadMore = true,
  } : {
    data?: Q;
    variables: V;
    fetchMore: (variables: FetchMoreQueryOptions<V>) => void;
    loading: boolean;
    showLoader: boolean;
    field: Exclude<keyof ArticlesConnectionQuery, '__typename'> |
    Exclude<keyof NonNullable<AuthorByEmailQuery[
      Exclude<keyof AuthorByEmailQuery, '__typename'>
    ]>, '__typename'>;
    connectionField?: Exclude<keyof AuthorByEmailQuery, '__typename'>;
    shouldLoadMore?: boolean;
  }) => {
  const { edges = [], pageInfo: { hasNextPage } = { hasNextPage: false } } = {
    ...(field === 'newsArticlesConnection' && connectionField
      ? (data as AuthorByEmailQuery | undefined)?.[
        connectionField as keyof AuthorByEmailQuery
      ]?.[field as keyof NonNullable<
      AuthorByEmailQuery[keyof AuthorByEmailQuery]
      >] as
        { edges?: { node: NewsArticle }[] } | undefined
      : (data as ArticlesConnectionQuery | undefined)?.[
        field as keyof ArticlesConnectionQuery
      ] as
        { edges?: { node: NewsArticle }[] } | undefined
    ) ?? { edges: [] },
  };
  return (
    <>
      <StyledList className="fu-mdc-list">
        {edges.length > 0 ? (edges.map((edge, index) => {
          const { node } = { ...edge };

          return (
            <NewsFeedListItem
              article={node}
              isPressRelease={node.pressRelease}
              isPartnerContent={node.partnerContent}
              variables={{
                pageviews: false,
                ...variables,
              }}
              isFirstImage={index === 0}
              key={node.id.toString()}
              showLoader={showLoader}
            />
          );
        })) : (showLoader ? (Array.from({ length: 4 }, (_, index) => (
          <Fragment key={`skeleton-${index}`}>
            <NewsFeedListItem
              showLoader
              variables={{
                pageviews: false,
                ...variables,
              }}
              key={`skeleton-${index}`}
              isPressRelease={false}
              isPartnerContent={false}
              isFirstImage={false}
            />
          </Fragment>
        ))) : null)}
      </StyledList>
      {shouldLoadMore && (
      <LoadMore
        loading={loading}
        hasNextPage={hasNextPage}
        fetchMore={
            () => loadMore({
              variables,
            })(data, field, fetchMore, connectionField)
          }
      />
      )}
    </>
  );
};

export default ArticleList;
